<template>

    <validation-observer ref="refForm">

        <b-form
            @submit.prevent="onSubmit"
            @reset.prevent="resetForm"
        >

            <b-row>
                <b-col md="12">
                    <b-card footer-tag="footer">

                        <b-row>

                            <!-- NAME(S) -->
                            <b-col md="6">
                                <b-form-group
                                    label="NOMBRE(S)"
                                    label-for="names"
                                >
                                    <validation-provider
                                        #default="{ errors }"
                                        rules="required"
                                        name="nombre(s)"
                                    >
                                        <b-form-input
                                            id="names"
                                            v-model="formData.names"
                                            placeholder="Ingrese el nombre ..."
                                            :state="errors.length > 0 ? false : null"
                                            trim
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <!-- SURNAME(S) -->
                            <b-col md="6">
                                <b-form-group
                                    label="APELLIDO(S)"
                                    label-for="surnames"
                                >
                                    <validation-provider
                                        #default="{ errors }"
                                        rules="required"
                                        name="apellido(s)"
                                    >
                                        <b-form-input
                                            id="surnames"
                                            v-model="formData.surnames"
                                            placeholder="Ingrese el apellido ..."
                                            :state="errors.length > 0 ? false : null"
                                            trim
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <!-- EMAIL -->
                            <b-col md="6">
                                <b-form-group
                                    label="CORREO ELECTRÓNICO"
                                    label-for="email"
                                    class="mb-0"
                                >
                                    <validation-provider
                                        #default="{ errors }"
                                        rules="required|email"
                                        name="correo electrónico"
                                    >
                                        <b-form-input
                                            id="email"
                                            v-model="formData.email"
                                            autocomplete="login-email"
                                            :state="errors.length > 0 ? false:null"
                                            trim
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                        </b-row>

                        <template #footer>

                            <!-- BUTTON SUBMIT -->
                            <b-button-loading
                                text="ACTUALIZAR"
                                type="submit"
                                :processing="processing">
                            </b-button-loading>

                        </template>

                    </b-card>
                </b-col>
            </b-row>

        </b-form>

    </validation-observer>

</template>

<script>

    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
    import BButtonLoading from '@core/components/b-button-loading/BButtonLoading.vue';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { ref, onUnmounted, onMounted } from '@vue/composition-api';
    import { useToast } from 'vue-toastification/composition';
    import accountStoreModule from './accountStoreModule';
    import { required, email } from '@validations';
    import { getUserData } from '@/auth/utils';
    import store from '@/store';

    export default {
        components: {
            ValidationProvider,
            ValidationObserver,

            BButtonLoading,

            required,
            email
        },
        setup() {

            // USE TOAST
            const toast = useToast();

            // REFS
            const userData = ref(getUserData());
            const refForm = ref(null);

            const formData = ref({
                names: null,
                surnames: null,
                email: null
            });

            const processing = ref(false);

            const ACCOUNT_APP_STORE_MODULE_NAME = 'app-account';

			// REGISTER MODULE
			if (!store.hasModule(ACCOUNT_APP_STORE_MODULE_NAME)) store.registerModule(ACCOUNT_APP_STORE_MODULE_NAME, accountStoreModule);

			// UNREGISTER ON LEAVE
			onUnmounted(() => {
				if (store.hasModule(ACCOUNT_APP_STORE_MODULE_NAME)) store.unregisterModule(ACCOUNT_APP_STORE_MODULE_NAME);
			});

            onMounted(() => {
                setFormData();
            });

            // METHODS
            const setFormData = () => {
                const { names, surnames, email } = userData.value;

                formData.value = {
                    names,
                    surnames,
                    email
                }
            };

            const onSubmit = async () => {

                const isValid = await refForm.value.validate();

                if (!isValid)
                {
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Complete los campos requeridos.',
                            icon: 'AlertTriangleIcon',
                            variant: 'warning'
                        }
                    });

                    return;
                }

                processing.value = true;

                const { names, surnames, email } = formData.value;

                const payload = {
                    names,
                    surnames,
                    email
                }

                store.dispatch('app-account/updateGeneralData', { payload })
                    .then( response => {

                        const { user } = response.data;

                        toast({
                            component: ToastificationContent,
                            props: {
                                title: response.data.message,
                                icon: 'CheckIcon',
                                variant: 'success'
                            }
                        });

                        userData.value.names = user.names;
                        userData.value.surnames = user.surnames;
                        userData.value.fullName = user.fullName;
                        userData.value.email = user.email;

                        localStorage.setItem('userData', JSON.stringify(userData.value));

                        setTimeout(() => {
                            window.location.reload();
                        }, 2500);

                    })
                    .catch( (err) => {

                        const message = err.response.data.message ? err.response.data.message : 'Error al actualizar los datos generales.';

                        toast({
                            component: ToastificationContent,
                            props: {
                                title: message,
                                icon: 'AlertTriangleIcon',
                                variant: 'danger'
                            }
                        });

                    })
                    .finally( () =>  processing.value = false);

            };

            return {
                // DATA
                processing,
                formData,

                // REFS
                refForm,

                // METHODS
                onSubmit
            }
        }
    }

</script>
